// システムリーチ追加
import getUrlQueries from './modules/get_url_queries.js'
import escapeAgainstXss from './modules/escape_against_xss.js'
import Swiper from 'swiper';
import { load } from 'recaptcha-v3'
const environmentJson = require("./environment.json")
const jsons = {};
let posY;
// IE11/デバイス判定 Class設定
function configuration() {
  $(function () {
    // ブラウザ IE11
    ! function () {
      let regexp = /msie|trident/i;
      if (window.navigator.userAgent.search(regexp) !== -1) {
        $(body).addClass('ua-ie');
      }
    }();
    // デバイス
    ! function () {
      ! function () {
        const winSize = window.matchMedia('(max-width: 1024px)');
        winSize.addListener(handle);
        function handle(mq) {
          if (mq.matches) {
            $(body).addClass('mq-sp');
          } else {
            $(body).removeClass('mq-sp');
          }
        }
        handle(winSize);
      }();
      ! function () {
        const regexp = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        if (window.navigator.userAgent.search(regexp) !== -1) {
          $(body).addClass('ua-sp');
        }
      }();
    }();
    // target="_blank" rel属性付与
    !function(){
      $('a[target="_blank"]').each(function() {
          $(this).attr('rel', 'noopener');
      });
    }();
  });
}

// 共通制御
function common() {
  $(function () {
    // メニュー
    !function(){
      const siteTrigger = '[data-js-trig="headerNavBtn"]',
            siteElement = '[data-js-elem="siteNav"]',
            searchTrigger = '[data-js-trig="subSearchMenubtn"]',
            searchElement = '[data-js-elem="search"]';
      !function(){
        $(document).on(click, siteTrigger+':not(.'+active+')', function() {
          $(this).addClass(active);
          $(siteElement).addClass(active);
          $(searchTrigger).removeClass(active);
          $(searchElement).removeClass(active);
          $(body).addClass('is-overlay');
          return false;
        });
        $(document).on(click, siteTrigger+'.'+active, function() {
          $(this).removeClass(active);
          $(siteElement).removeClass(active);
          $(body).removeClass('is-overlay');
          return false;
        });
      }();
      !function(){
        $(document).on(click, searchTrigger+':not(.'+active+')', function() {
          $(this).addClass(active);
          $(searchElement).addClass(active);
          $(siteTrigger).removeClass(active);
          $(siteElement).removeClass(active);
          $(body).addClass('is-overlay');
          return false;
        });
        $(document).on(click, searchTrigger+'.'+active, function() {
          $(this).removeClass(active);
          $(searchElement).removeClass(active);
          $(body).removeClass('is-overlay');
          return false;
        });
      }();
    }();
    // スムーススクロール
    !function(){
      const trigger = 'a[data-js-trig="anchorLink"]';
      $.easing.quart = function(x, t, b, c, d) {
        return -c * ((t = t / d - 1) * t * t * t - 1) + b;
      };
      $(document).on(click, trigger, function() {
        let href= $(this).attr('href'),
            target = $(href == "#" || href == "" ? 'html' : href);

        pos = target.offset().top;
        $('html, body').animate({ scrollTop: pos }, 400);
        return false;
      });
    }();
    // タブ
    !function(){
      const tabs = '[data-js-elem="tabConts"]',
            tabNav = '[data-js-trig="tabNav"]';
      $(document).on(click, tabNav, function() {
        var container = $(this).parents('[data-js-elem="tabCtr"]');
        container.find(tabNav+'.'+active).removeClass(active);
        $(this).addClass(active);
        container.find(tabs+'.'+active).removeClass(active);
        var index = $(this).index();
        container.find(tabs).eq(index).addClass(active);
      });
    }();
    // メニュー
    !function(){
      const open = '[data-js-trig="menu"]';
      $(document).on(click, open, function() {
        $(this).next('[data-js-elem="menulist"]').toggleClass(active);
      });
      const close = '[data-js-trig="menuitem"]';
      $(document).on(click, close, function() {
        // システムリーチ追記
        if($(this).hasClass("comment-edit")) {
          // コメント編集の場合
          let comment_form = $(this).closest(".modal-inner").find("form")
          let matching_message_id = $(this).data().matchingMessageId
          comment_form.attr("action", `/matching/messages/${matching_message_id}`)
          comment_form.find("textarea").val($(this).data().matchingMessageContent)
          if(!comment_form.find("input[name='matching_message[id]']").length) {
            // 新規コメントからコメント編集に切り替える場合
            comment_form.append('<input type="hidden" name="_method" value="put">')
            comment_form.append(`<input type="hidden" value="${matching_message_id}" name="matching_message[id]">`)
          } else {
            // 他のコメントの編集中の場合
            comment_form.find("input[name='matching_message[id]']").val(matching_message_id)
          }
        }
        // システムリーチ追記ここまで

        $(this).parents('[data-js-elem="menulist"]').removeClass(active);
      });
      
      // システムリーチ追記
      $(document).on(click, ".comment-edit-cancel", function() {
        //　コメントモーダルのキャンセルボタンが押下された時
        const comment_form = $(this).closest("form")
        $(this).closest("form").find("textarea").val("")
        if($(this).closest("form").find("input[name='matching_message[id]']").length > 0) {
          // コメント編集中の場合
          $(this).closest("form").find("input[name='matching_message[id]']").remove()
          $(this).closest("form").find("input[name='_method").remove()
        }
      })
    }();
    // SVG配色
    !function(){
      deSVG('[data-js-elem="desvg"]', true);
    }();
  });
}

// フォーム制御
function form() {
  $(function () {
    // シークレットエリア表示
    !function(){
      const trigger = '[data-js-trigger="secretBtn"]';
      // システムリーチ追記
      if ($(document).find(trigger).length == 0) {
        return
      }
      $(document).on(click, trigger, function () {
        $(this).toggleClass('is-show');
        const input = $(this).prev('input');
        if (input.attr('type') == 'text') {
          input.attr('type','password');
        } else {
          input.attr('type','text');
        }
      });
    }();
    // ラジオボタン判定
    !function(){
      const trigger = '[data-js-trigger="radioBtnMedia"]';
      // システムリーチ追記
      if ($(document).find(trigger).length == 0) {
        return
      }
      $(document).on(click, trigger, function () {
        if ($('input[type=radio][name=media]#media03:checked').is(':checked')) {
          $('[data-js-elem="radioBtnMedia"]').removeClass('is-disable');
        } else {
          $('[data-js-elem="radioBtnMedia"]').addClass('is-disable');
        }
      });
    }();
    // チェックボックス判定
    !function(){
      const trigger = '[data-js-trigger="checkBox"]';
      $(document).on(click, trigger, function () {
        if ($(this).is(':checked')) {
          $('[data-js-elem="checkBox"]').addClass('is-disable');
        } else {
          $('[data-js-elem="checkBox"]').removeClass('is-disable');
        }
      });
    }();
    // ファイルアップロード
    !function(){
      const trigger = '[data-js-trigger="fileupload"]';
      // システムリーチ追記
      if ($(document).find(trigger).length == 0) {
        return
      }
      $(document).on('change', trigger, function () {
        // システムリーチ追記
        if (!$(this).prop('files')[0]) {
          return;
        }
        const filename = $(this).prop('files')[0].name;
        $(this).parents('[data-js-elem="fileuploadCtr"]').addClass('is-upload');
        $(this).nextAll('[data-js-elem="fileuploadText"]').html('<span class="filename">'+filename+'</span><span class="mi mi-close" data-js-trigger="fileuploadDel"></span>');
      });
      $(document).on(click, '[data-js-trigger="fileuploadDel"]', function () {
        $(this).parents('[data-js-elem="fileuploadCtr"]').removeClass('is-upload');
        $(this).parents('[data-js-elem="fileuploadText"]').html('選択されていません');
        $(this).parents('[data-js-elem="fileuploadText"]').prev(trigger).val(null);
      });
    }();
    // プロジェクト複製
    // システムリーチ修正（スキルシートのプロジェクト追加処理はJavascriptでは不可）
    // !function(){
    //   let number = 1;
    //   const trigger = '[data-js-trigger="projectAdd"]',
    //         elem = '[data-js-elem="projectCtr"]',
    //         project = '[data-js-elem="project"]';
    //   $(document).on(click, trigger, function () {
    //     let add = number + 1;
    //     // 要素複製
    //     const cloneHtml = $(''+project+'[data-project-num="'+number+'"]').clone(true, false);
    //     const cloneEnvListHtml = $('.modal-environment.modal-skill[data-mfp-skill="'+number+'"]').clone(true, false);
    //     // タイトル
    //     const title = cloneHtml.find('[data-replace-elem="ttl"]'),
    //           titleText = $(title).text().replace(number,add);
    //     $(title).text(titleText);
    //     // 包括
    //     const ctr = cloneHtml.find('[data-js-elem="fomrItemId"]');
    //     $(ctr).each(function() {
    //       const thisId = $(this).attr('id').replace(number,add);
    //       $(this).attr('id', thisId);
    //     });
    //     // フォーム
    //     const name = cloneHtml.find('[data-replace-elem="name"]'),
    //           nameId = cloneHtml.find('[data-replace-elem="nameId"]'),
    //           label = cloneHtml.find('[data-replace-elem="for"]'),
    //           textarea = cloneHtml.find('[data-replace-elem="textarea"]'),
    //           env = cloneHtml.find('[data-js-elem="fomrItemId"]'),
    //           modalBtn = cloneHtml.find('[data-js-trigger="mfpEnv"]');
    //     $(name).each(function() {
    //       const thisName = $(this).attr('name').replace(number,add);
    //       $(this).attr('name', thisName);
    //     });
    //     $(nameId).each(function() {
    //       const thisName = $(this).attr('name').replace(number,add),
    //             thisId = $(this).attr('id').replace(number,add);
    //       $(this).attr({
    //         'name': thisName,
    //         'id': thisId
    //       });
    //     });
    //     $(label).each(function() {
    //       const thisfor = $(this).attr('for').replace(number,add);
    //       $(this).attr('for', thisfor);
    //     });
    //     $(textarea).each(function() {
    //       const thisTextarea = $(this).attr('name').replace(number,add);
    //       $(this).attr('name', thisTextarea);
    //     });
    //     // 環境
    //     $(env).each(function(i, e) {
    //       if(i === 0) {
    //         $(this).find('[data-js-elem="clickList"] li').remove();
    //       } else {
    //         $(this).remove();
    //       }
    //     });
    //     // 環境モーダル
    //     const thisHref = $(modalBtn).attr('href').replace(number,add);
    //     const thisModalId = $(cloneEnvListHtml).attr('id').replace(number,add),
    //           thisModalNumCtr = $(cloneEnvListHtml).attr('data-mfp-skill').replace(number,add),
    //           // システムリーチ修正
    //           // thisModalNumForm = $(cloneEnvListHtml).find('form').attr('data-mfp-skill-form').replace(number,add),
    //           thisModalNumForm = $(cloneEnvListHtml).find('.form').attr('data-mfp-skill-form').replace(number,add),
    //           formCheck = $(cloneEnvListHtml).find('input[name="envCheck"]');
    //     $(modalBtn).attr('href', thisHref);
    //     $(cloneEnvListHtml).attr({
    //       'id': thisModalId,
    //       'data-mfp-skill': thisModalNumCtr
    //     }).find('form').attr('data-mfp-skill-form', thisModalNumForm);
    //     $(formCheck).each(function(i, e) {
    //       if(i != 0) {
    //         $(this).prop('checked', false).change();
    //       }
    //     });
    //     // 出力
    //     cloneHtml.appendTo(elem).attr('data-project-num', add);
    //     cloneEnvListHtml.appendTo('[data-modal-ctr="skillsheet"]');
    //     // 加算
    //     number++;
    //     // イベント追加
    //     autoComplete();
    //     mfp();
    //   });
    // }();
  });
}

// モーダル
function mfp() {
  $(function () {
    // インライン
    !function(){
      const trigger = '[data-js-trigger="mfpInlineCenter"]';
      // システムリーチ追記
      if ($(document).find(trigger).length == 0) {
        return
      }
      $(document).find(trigger).magnificPopup({
        type: 'inline',
        alignTop: false,
        removalDelay: 250,
        closeMarkup: '<button title="Close (Esc)" type="button" class="mfp-close"></button>',
        callbacks: {
          open: function () {
            modalOpen();
          },
          close: function () {
            setTimeout(function(){ modalClose(); }, 250);
          },
          beforeOpen: function () {
            this.st.mainClass = this.st.el.attr('data-js-mfp');
          }
        }
      });
    }();
    // ステータス
    !function(){
      const trigger = '[data-js-trigger="statusModal"]',
            modalCond = '案件にエントリー中／ご提案中 || 提案中 || インタビュー見送り || インタビュー希望 || 成約';
      // システムリーチ追記
      if ($(document).find(trigger).length == 0) {
        return
      }
      // システムリーチ修正
      // let before,
      //     after;
      let before,
          before_val,
          after,
          changed_flag;
      $(document).on('focus', trigger, function () {
        // システムリーチ修正
        // before = $(this).val();
        before = $(this).children(':selected').text();
        before_val = $(this).val();
      });
      $(document).on('change', trigger, function () {
        // システムリーチ修正＆追記
        // after = $(this).val();
        after = $(this).children(':selected').text();
        let focused_select_box = $(this)
        if(modalCond.includes(after)) {
          $.magnificPopup.open({
            type: 'inline',
            alignTop: false,
            removalDelay: 250,
            items: { src: '#statusMessage' },
            modal: true,
            mainClass: 'mfp-fade mfp-status',
            closeMarkup: '<button title="Close (Esc)" type="button" class="mfp-close"></button>',
            callbacks: {
              open: function () {
                modalOpen();
                if(before != null) {
                  $('[data-js-elem="before"]').html('「'+before+'」→');
                } else {
                  $('[data-js-elem="before"]').html('');
                }
                $('[data-js-elem="after"]').html('「'+after+'」');
                // システムリーチ追記
                // html側も修正が必要。（具体的には、モーダルの「変更する」ボタンのidを「status-change-button」にする。）
                $("#status-change-button").click(function(e) {
                  changed_flag = true
                  setTimeout(function(){ focused_select_box.parent().submit(); }, 250);
                  // setTimeout(function(){ modalClose(); }, 250);
                  $.magnificPopup.close();
                })
              },
              close: function () {
                // システムリーチ追記
                if (!changed_flag) {
                  focused_select_box.val(before_val)
                }
                setTimeout(function(){ modalClose(); }, 250);
              }
            }
          });
        } else {
          before = $('.page-list-entry select[name="status"]').val();
          // システムリーチ追記
          focused_select_box.parent().submit();
        }
      });
      $(document).on(click, '[data-js-trigger="mfpClose"]', function() {
        // システムリーチ追加
        $.magnificPopup.close();
      });
    }();
    !function(){
      const trigger = '[data-js-trigger="mfpInline"]';
      // システムリーチ追記
      if ($(document).find(trigger).length == 0) {
        return
      }
      $(document).find(trigger).magnificPopup({
        type: 'inline',
        alignTop: true,
        removalDelay: 250,
        closeMarkup: '<button title="Close (Esc)" type="button" class="mfp-close"></button>',
        callbacks: {
          open: function () {
            modalOpen();
          },
          close: function () {
            setTimeout(function(){ modalClose(); }, 250);
          },
          beforeOpen: function () {
            this.st.mainClass = this.st.el.attr('data-js-mfp');
          }
        }
      });
      // 環境階層メニュー
      $(document).on(click, '[data-js-trigger="hmenuLabel"]', function() {
        $('[data-js-hmenu="environment"][data-js-elem="hmenu"]').addClass(active);
        $(this).next('[data-js-elem="hmenuChild"]').addClass(active);
      });
      // 戻る
      $(document).on(click, '[data-js-trigger="checkBack"]', function() {
        $('[data-js-hmenu="environment"][data-js-elem="hmenu"]').removeClass(active);
        $('[data-js-hmenu="environment"] [data-js-elem="hmenuChild"]').removeClass(active);
      });
      // 閉じる
      $(document).on(click, '[data-js-trigger="checkClose"]', function() {
        $(this).parents('.btn-ctr').prev('[data-js-elem="hmenu"]').removeClass(active);
        $('[data-js-hmenu="environment"] [data-js-elem="hmenuChild"]').removeClass(active);
        $(trigger).magnificPopup('close');
      });
      // 検索
      $(document).on(click, '[data-js-trigger="checkSubmit"]', function() {
        // 職種
        !function(){
          let occupationCheck = [];
          $('[name="occupationCheck"]:checked').each(function(){
            occupationCheck.push($(this).val());
          });
          $('[data-js-elem="occupationOutputText"]').html('');
          $.each(occupationCheck, function(i, v) {
            let occupationList = '<p class="text">'+v+'</p>';
            $('[data-js-elem="occupationOutputText"]').append(occupationList);
          });
          $('[data-js-hidden="occupation"]').attr('value', occupationCheck);
        }();
        // 環境
        !function(){
          let envCheck = [];
          $('[name="envCheck"]:checked').each(function(){
            envCheck.push($(this).val());
          });
          $('[data-js-elem="envOutputText"]').html('');
          $.each(envCheck, function(i, v) {
            let envList = '<p class="text">'+v+'</p>';
            $('[data-js-elem="envOutputText"]').append(envList);
          });
          $('[data-js-hidden="environment"]').attr('value', envCheck);
        }();
        // 役割
        !function(){
          let roleCheck = [];
          $('[name="roleCheck"]:checked').each(function(){
            roleCheck.push($(this).val());
          });
          $('[data-js-elem="roleOutputText"]').html('');
          $.each(roleCheck, function(i, v) {
            let roleList = '<p class="text">'+v+'</p>';
            $('[data-js-elem="roleOutputText"]').append(roleList);
          });
          $('[data-js-hidden="role"]').attr('value', roleCheck);
        }();
        // 都道府県
        !function(){
          let prefCheck = [];
          $('[name="prefCheck"]:checked').each(function(){
            prefCheck.push($(this).val());
          });
          $('[data-js-elem="prefOutputText"]').html('');
          $.each(prefCheck, function(i, v) {
            let prefList = '<p class="text">'+v+'</p>';
            $('[data-js-elem="prefOutputText"]').append(prefList);
          });
          $('[data-js-hidden="pref"]').attr('value', prefCheck);
        }();
        // 閉じる処理
        $(this).parents('.btn-ctr').prev('[data-js-elem="hmenu"]').removeClass(active);
        $('[data-js-hmenu="environment"] [data-js-elem="hmenuChild"]').removeClass(active);
        $(trigger).magnificPopup('close');
      });
    }();
    // 絞り込み検索
    !function(){
      const trigger = '[data-js-trigger="mfpFilter"]';
      // システムリーチ追記
      if ($(document).find(trigger).length == 0) {
        return
      }
      let filterArr = [];
      $(document).find(trigger).magnificPopup({
        type: 'inline',
        alignTop: true,
        removalDelay: 250,
        closeMarkup: '<button title="Close (Esc)" type="button" class="mfp-close"></button>',
        callbacks: {
          open: function () {
            modalOpen();
            filterArr = [];
          },
          close: function () {
            setTimeout(function(){
              modalClose();
              $('[data-js-hidden="searchFilter"]').attr('value', filterArr);
              if($('[data-js-elem="filterOutput"]').length){
                $('[data-js-elem="searchFilter"]').removeClass('is-hide');
              } else {
                $('[data-js-elem="searchFilter"]').addClass('is-hide');
              }
            }, 250);
          },
          beforeOpen: function () {
            this.st.mainClass = this.st.el.attr('data-js-mfp');
          }
        }
      });
      // 階層メニュー
      $(document).on(click, '[data-js-trigger="hmenuLabel"]:not(.is-active)', function() {
        $('[data-js-hmenu="filter"][data-js-elem="hmenu"]').addClass(active);
        $(this).addClass(active);
        $(this).next('[data-js-elem="hmenuChild"]').addClass(active);
      });
      $(document).on(click, '[data-js-trigger="hmenuLabel"].is-active', function() {
        $(this).removeClass(active);
        $(this).next('[data-js-elem="hmenuChild"]').removeClass(active);
      });
      // 検索
      $(document).on(click, '[data-js-trigger="filterSubmit"]', function() {
        // クリア
        $('[data-js-elem="filterClickList"]').html('');
        // フィルタ項目設定
        !function(){
          const inputElem = $('[data-js-hmenu="filter"]').find('[data-js-elem="filterInput"]');
          $(inputElem).each(function(){
            const id = $(this).attr('id'),
                  type = $(this).attr('type'),
                  name = $(this).attr('name'),
                  range = $(this).attr('data-js-elem-input'),
                  formlabel = $(this).parents('[data-js-input]').attr('data-js-elem-label');
            let value,
                list;
            if(range != undefined) {
              // システムリーチ修正
              // const valueLower = $(this).val(),
              //       idUpper = $(this).parents('[data-js-elem="range"]').find('[data-js-elem-input="rangeUpper"]').attr('id'),
              //       valueUpper = $(this).parents('[data-js-elem="range"]').find('[data-js-elem-input="rangeUpper"]').val(),
              //       rangeType = $(this).parents('[data-js-elem="range"]').attr('data-js-elem-range');
              const valueLower = escapeAgainstXss($(this).val()),
                    idUpper = $(this).parents('[data-js-elem="range"]').find('[data-js-elem-input="rangeUpper"]').attr('id'),
                    valueUpper = escapeAgainstXss($(this).parents('[data-js-elem="range"]').find('[data-js-elem-input="rangeUpper"]').val()),
                    rangeType = $(this).parents('[data-js-elem="range"]').attr('data-js-elem-range');
              
              let unit;
              if(rangeType === 'price') {
                unit = '万円';
              } else if(rangeType === 'percent') {
                unit = '％';
              } else if(rangeType === 'people') {
                unit = '人';
              } else {
                unit = '';
              }
              if(valueLower != '' && valueUpper != '') {
                list = '<li class="click-list-item" data-js-elem="clickListItem"><div class="btn btn-select"><button class="btn-item" type="button" value="'+formlabel+'：'+valueLower+'～'+valueUpper+unit+'" data-js-id="'+id+'" data-js-elem="filterOutput">'+
                             '<span class="btn-select-label">'+formlabel+'：'+valueLower+'～'+valueUpper+unit+'</span>'+
                             '<span class="mi mi-close-after" data-js-trigger="clickFilterClose" data-js-source="filter"></span></button></div></li>';
                $('[data-js-elem="filterClickList"]').append(list);
                filterArr.push(formlabel+'：'+valueLower+'～'+valueUpper+unit);
              } else if(valueLower != '') {
                list = '<li class="click-list-item" data-js-elem="clickListItem"><div class="btn btn-select"><button class="btn-item" type="button" value="'+formlabel+'：'+valueLower+unit+'" data-js-id="'+id+'" data-js-elem="filterOutput">'+
                             '<span class="btn-select-label">'+formlabel+'：'+valueLower+unit+'</span>'+
                             '<span class="mi mi-close-after" data-js-trigger="clickFilterClose" data-js-source="filter"></span></button></div></li>';
                $('[data-js-elem="filterClickList"]').append(list);
                filterArr.push(formlabel+'：'+valueLower+unit);
              } else if(valueUpper != '' ) {
                list = '<li class="click-list-item" data-js-elem="clickListItem"><div class="btn btn-select"><button class="btn-item" type="button" value="'+formlabel+'：'+valueUpper+unit+'" data-js-id="'+idUpper+'" data-js-elem="filterOutput">'+
                             '<span class="btn-select-label">'+formlabel+'：'+valueUpper+unit+'</span>'+
                             '<span class="mi mi-close-after" data-js-trigger="clickFilterClose" data-js-source="filter"></span></button></div></li>';
                $('[data-js-elem="filterClickList"]').append(list);
                filterArr.push(formlabel+'：'+valueUpper+unit);
              }
            } else {
              let inputLabel;
              if(name === 'achievement') {
                inputLabel = '実績：';
              } else {
                inputLabel = '';
              }
              if(type === 'checkbox') {
                const valueProp = $(this).prop('checked');
                if(valueProp != false) {
                  if(id === 'belongs02') {
                    // システムリーチ修正
                    // value = $('#belongsText').val();
                    value = escapeAgainstXss($('#belongsText').val());
                  } else {
                    // システムリーチ修正
                    // value = $(this).val();
                    value = escapeAgainstXss($(this).val());
                  }
                } else {
                  value = '';
                }
              } else {
                // システムリーチ修正
                // value = $(this).val();
                value = escapeAgainstXss($(this).val());
              }
              if(value != '') {
                list = '<li class="click-list-item" data-js-elem="clickListItem">'+
                      '<div class="btn btn-select"><button class="btn-item" type="button" value="'+value+'" data-js-id="'+id+'" data-js-elem="filterOutput">'+
                      '<span class="btn-select-label">'+formlabel+'：'+inputLabel+value+'</span>'+
                      '<span class="mi mi-close-after" data-js-trigger="clickFilterClose" data-js-source="filter"></span></button></div></li>';
                $('[data-js-elem="filterClickList"]').append(list);
                filterArr.push(inputLabel+value);
              }
            }
          });
          $(trigger).magnificPopup('close');
        }();
      });
      // システムリーチ追記（検索画面の初期値を画面に反映する処理）
      // クエリーから検索項目初期値を取得してポップアップの入力項目に反映
      let queries = getUrlQueries()
      Object.keys(queries).forEach(key => {
        if (Array.isArray(queries[key])) {
          // チェックボックスの場合
          const values = queries[key]
          values.forEach(value => {
            $(`input[name="${key}"][value="${value}"]`).prop('checked', true)
          })
        } else {
          // 単一の値（テキストボックスなど）の場合
          $(`input[name="${key}"]`).val(escapeAgainstXss(queries[key]))
        }
      })
      // 検索項目の初期値を表示させるために検索項目確定ボタンのクリック処理を発火
      // 検索項目設定モーダルのmagnificPopup設定の後に記載すること。（JSソース更新時に注意）
      $('[data-js-trigger="filterSubmit"]').trigger("click")
      // 下記5行を元のJSソースからコピペ（検索項目がセットされている場合はis-hidden クラスのremove）
      $('[data-js-hidden="searchFilter"]').attr('value', filterArr);
      if($('[data-js-elem="filterOutput"]').length){
        $('[data-js-elem="searchFilter"]').removeClass('is-hide');
      } else {
        $('[data-js-elem="searchFilter"]').addClass('is-hide');
      }
      // ボタン削除
      $(document).on(click, '[data-js-trigger="clickFilterClose"]', function () {
        // フォームクリア
        const buttonId = String($(this).parents('[data-js-elem="filterOutput"]').attr('data-js-id')),
              buttonValue = $(this).parents('[data-js-elem="filterOutput"]').attr('value');
        if(buttonId === 'belongs02') {
          $('#filter input#belongs02').removeAttr('checked').prop('checked', false).change();
          $('#filter input#belongsText').val('');
        } else if(buttonId.match(/Lower$/) != null) {
          const buttonIdUpper = buttonId.replace('Lower', 'Upper');
          $('#filter input[type="text"]#'+buttonId+', #filter input[type="date"]#'+buttonId+'').val('');
          $('#filter input[type="checkbox"]#'+buttonId+'').removeAttr('checked').prop('checked', false).change();
          $('#filter input[type="text"]#'+buttonIdUpper+', #filter input[type="date"]#'+buttonIdUpper+'').val('');
          $('#filter input[type="checkbox"]#'+buttonIdUpper+'').removeAttr('checked').prop('checked', false).change();
        } else {
          $('#filter input[type="text"]#'+buttonId+', #filter input[type="date"]#'+buttonId+'').val('');
          $('#filter input[type="checkbox"]#'+buttonId+'').removeAttr('checked').prop('checked', false).change();
        }
        // hidden配列削除
        const hiddenValue = $('[data-js-hidden="searchFilter"]').attr('value');
        const hiddenValueReplace = hiddenValue.replace(buttonValue, '').replace(',,', '');
        $('[data-js-hidden="searchFilter"]').attr('value', hiddenValueReplace);
        // ボタン/エリア非表示
        $(this).parents('[data-js-elem="clickListItem"]').remove();
        setTimeout(function(){
          if($('[data-js-elem="filterOutput"]').length){
            $('[data-js-elem="searchFilter"]').removeClass('is-hide');
          } else {
            $('[data-js-elem="searchFilter"]').addClass('is-hide');
          }
        }, 250);
      });
    }();
    // 環境
    !function(){
      let ctrNum,
          idCheck;
      const trigger = '[data-js-trigger="mfpEnv"]';
      // システムリーチ追記
      if ($(document).find(trigger).length == 0) {
        return
      }
      $(document).find(trigger).magnificPopup({
        type: 'inline',
        alignTop: true,
        removalDelay: 250,
        closeMarkup: '<button title="Close (Esc)" type="button" class="mfp-close"></button>',
        callbacks: {
          open: function () {
            modalOpen();
          },
          close: function () {
            ctrNum = '';
            setTimeout(function(){ modalClose(); }, 250);
          },
          beforeOpen: function () {
            this.st.mainClass = this.st.el.attr('data-js-mfp');
            ctrNum = this.st.el.parents('[data-js-elem="project"]').attr('data-project-num');
            idCheck = [];
            $('[data-js-elem="fomrItemId"]').each(function(){
              const id = $(this).attr('id');
              if(id != undefined) {
                idCheck.push(id);
              }
            });
          }
        }
      });
      // 環境項目追加
      $(document).on(click, '[data-js-trigger="envSelect"]', function() {
        let envCheck = [],
            envNotCheck = [],
            envCtr;
        if(ctrNum != undefined){
          ctrNum = ctrNum;
        } else {
          ctrNum = '';
        }
        if($('.wrapper ').hasClass('page-skillsheet')){
          if(ctrNum != ''){
            envCtr = '[data-js-elem="project"][data-project-num="'+ctrNum+'"] [data-js-elem="envCtr"]';
            $('[data-mfp-skill-form="'+ctrNum+'"] [name="envCheck"]:checked').each(function(){
              envCheck.push({ 'value': $(this).val(), 'id': $(this).attr('id') });
            });
            $('[data-mfp-skill-form="'+ctrNum+'"] [name="envCheck"]:not(:checked)').each(function(){
              envNotCheck.push({ 'value': $(this).val(), 'id': $(this).attr('id') });
            });
          }
        } else {
          envCtr = '[data-js-elem="envCtr"]';
          $('[name="envCheck"]:checked').each(function(){
            envCheck.push({ 'value': $(this).val(), 'id': $(this).attr('id') });
          });
          $('[name="envCheck"]:not(:checked)').each(function(){
            envNotCheck.push({ 'value': $(this).val(), 'id': $(this).attr('id') });
          });
        }
        // let html_id_to_table_column = {
        //   "Os": { "table_column": "os", "table_other_column": "os_other" },
        //   "Language": { "table_column": "languages", "table_other_column": "languages_other" }
        // }
        $.each(envCheck, function(i, v) {
          // システムリーチ修正
          // if($.inArray(v.id+ctrNum, idCheck) === -1) {
          //   let envHtml  = '<div class="form-item-inner" id="'+v.id+ctrNum+'" data-js-elem="fomrItemId">';
          //     envHtml += '<div class="form-input-ctr">';
          //     envHtml += '<p class="form-label">'+v.value+'</p>';
          //     envHtml += '<div class="form-input form-input-text form-input-autocomplete">';
          //     envHtml += '<ul class="click-list" data-js-elem="clickList"></ul>';
          //     envHtml += '<input type="text" name="'+v.id+ctrNum+'" placeholder="キーワードを入力の上、選択してください" data-js-elem="autocomplete" data-js-source="'+v.id+'" data-replace-elem="name">';
          //     envHtml += '<div class="text-list" data-js-elem="textList"></div>';
          //     envHtml += '<div class="autocomplete-nofound" data-js-elem="nofound"></div>';
          //     // envHtml += '<input type="hidden" name="'+v.id+ctrNum+'" value="" data-js-autocomplete="hidden" data-replace-elem="name">';
          //     envHtml += '<input type="hidden" name="matching_skillsheet_items_attributes['+ctrNum+']['+html_id_to_table_column[v.id].table_column+']" value="" data-js-autocomplete="hidden" data-replace-elem="name">';
          //     envHtml += '</div>';
          //     envHtml += '</div>';
          //     envHtml += '<div class="form-input-ctr">';
          //     envHtml += '<p class="form-label">その他</p>';
          //     envHtml += '<div class="form-input form-input-text">';
          //     // envHtml += '<input type="text" name="'+v.id+ctrNum+'" placeholder="カンマ区切りで入力してください" data-replace-elem="name">';
          //     envHtml += '<input type="text" name="matching_skillsheet_items_attributes['+ctrNum+']['+html_id_to_table_column[v.id].table_other_column+']" placeholder="カンマ区切りで入力してください" data-replace-elem="name">';
          //     envHtml += '</div>';
          //     envHtml += '<p class="form-input-notes form-input-notes-env">※左側の項目に入力したい選択肢が表示されない場合には「その他」の項目に入力したい項目を入力してください。（カンマ区切りで入力してください）<br>例）Java,PhP</p>';
          //     envHtml += '</div>';
          //     envHtml += '</div>';
          //   $(envCtr).append(envHtml);
          // }
          // システムリーチ追記
          $(envCtr).find(`div[name=${v.id}]`).show()
        });
        $.each(envNotCheck, function(i, v) {
          // システムリーチ修正
          // if($.inArray(v.id+ctrNum, idCheck) != -1) {
          //   $(envCtr).find('#'+v.id+ctrNum+'').remove();
          // }
          // システムリーチ追記
          // 対象のDivを取得
          const target_div = $(envCtr).find(`div[name=${v.id}]`)
          // テキストボックスの値を空にする
          target_div.find("input").val("")
          // ボタンタグを全て消す（オートコンプリートの選択肢に反映されるように、Domのクリックイベントで削除する。）
          target_div.find("ul").children().each((index, element) => {
            $(element).find('[data-js-trigger="clickListClose"]').trigger("click");
          })
          // 非表示にする
          $(envCtr).find(`div[name=${v.id}]`).hide()
        });
        $(trigger).magnificPopup('close');
        autoComplete();
      });
    }();
    // 会員登録完了（仮実装）
    !function(){
      const trigger = '[data-js-trigger="mfpInlineLoad"]';
      // システムリーチ追加
      if ($(document).find(trigger).length == 0) {
        return
      }
      $(document).find(trigger).magnificPopup({
        type: 'inline',
        alignTop: true,
        removalDelay: 250,
        closeMarkup: '<button title="Close (Esc)" type="button" class="mfp-close"></button>',
        callbacks: {
          open: function () {
            modalOpen();
          },
          close: function () {
            setTimeout(function(){ modalClose(); }, 250);
          },
          beforeOpen: function () {
            this.st.mainClass = this.st.el.attr('data-js-mfp');
          }
        }
      });
      $(trigger).trigger('click');
    }();
  });
  // モーダル非表示時
  function modalClose() {
    $('html').removeAttr('style');
    $('html, body').prop({ scrollTop: posY });
  }
  // モーダル表示時
  function modalOpen() {
    posY = $(window).scrollTop();
    $('html').css({
      position: 'fixed',
      width: '100%',
      top: -1 * posY
    });
  }
}

// オートコンプリート
function autoComplete() {
  // システムリーチ修正
  // autocompleteで使用する値候補
  // $.ajax({
  //   type: 'GET',
  //   url: '../assets/js/json/environment.json',
  //   dataType: 'json',
  //   async: false
  // }).then(
  //   function (json) {
      $(window).on('load', function() {
        // システムリーチ修正
        $('[data-js-elem="autocomplete"]').each(function(index, element) {
          // システムリーチ追加
          const projectElement = $(this).parents('[data-js-elem="project"]')
          const projectNum = projectElement.attr('data-project-num') || `${index}`;
          jsons[projectNum] ? null : jsons[projectNum] = JSON.parse(JSON.stringify(environmentJson));
          const setData = $(this).attr('data-js-source'),
                getName = $(this).nextAll('input[type="hidden"]').attr('name'),
                getValue = $(this).nextAll('input[type="hidden"]').attr('value');
          let loadValue;
          if(getValue != undefined && getValue != '') {
            // システムリーチ修正
            // loadValue = getValue.split(',');
            loadValue = escapeAgainstXss(getValue).split(',');
            $.each(loadValue, function(i, e) {
              // ボタン出力
              let buttonHtml = '<li class="click-list-item" data-js-elem="clickListItem">'+
                                '<div class="btn btn-select">'+
                                  '<button class="btn-item" type="button" value="'+setData+'_'+e+'" data-js-value="'+e+'">'+
                                    '<span class="btn-select-label">'+e+'</span>'+
                                    '<span class="mi mi-close-after" data-js-trigger="clickListClose" data-js-source="'+setData+'"></span>'+
                                  '</button>'+
                                '</div>'+
                              '</li>';
              $('[data-js-elem="autocomplete"][name="'+getName+'"]').prev('[data-js-elem="clickList"]').append(buttonHtml);
              // 配列から削除
              // システムリーチ修正
              const idx = jsons[projectNum][''+setData+''].indexOf(e);
              if(idx >= 0){
                // システムリーチ修正
                jsons[projectNum][''+setData+''].splice(idx, 1);
              }
            });
          }
        });
      });
      // システムリーチ修正
      $('[data-js-elem="autocomplete"]').each(function(index, element) {
        // システムリーチ追加
        const projectElement = $(this).parents('[data-js-elem="project"]')
        const projectNum = projectElement.attr('data-project-num') || `${index}`;
        jsons[projectNum] ? null : jsons[projectNum] = JSON.parse(JSON.stringify(environmentJson));
        const setlist = $(this).next('[data-js-elem="textList"]'),
              setData = $(this).attr('data-js-source');
        $(this).autocomplete({
          minLength: 0,
          // システムリーチ修正
          source: jsons[projectNum][''+setData+''],
          appendTo: setlist,
          autoFocus: true,
          collapsible: true,
          delay: 400,
          response: function(event, ui) {
            if (ui.content.length === 0) {
              $(this).nextAll('[data-js-elem="nofound"]').addClass(active).text('No results found');
            } else {
              $(this).nextAll('[data-js-elem="nofound"]').removeClass(active).empty();
            }
            setTimeout(function(){
              $.each( ui.content, function( index, value ) {
                const elem = index;
                $('.ui-menu-item').eq(elem).attr('data-js-value', value.value + setData);
              });
              setlist.addClass(active);
            },200);
          },
          select: function(event, ui) {
            // ボタン生成
            const item = ui.item;
            let buttonHtml = '<li class="click-list-item" data-js-elem="clickListItem">'+
                              '<div class="btn btn-select">'+
                                '<button class="btn-item" type="button" value="'+setData+'_'+item.value+'" data-js-value="'+item.value+'">'+
                                  '<span class="btn-select-label">'+item.value+'</span>'+
                                  '<span class="mi mi-close-after" data-js-trigger="clickListClose" data-js-source="'+ setData+'"></span>'+
                                '</button>'+
                              '</div>'+
                            '</li>';
            $(this).prev('[data-js-elem="clickList"]').append(buttonHtml);
            // テキストエリアクリア
            $(this).val('');
            setlist.removeClass(active);
            // 配列から削除
            // システムリーチ修正
            const idx = jsons[projectNum][''+setData+''].indexOf(item.value);
            if(idx >= 0){
              // システムリーチ修正
              jsons[projectNum][''+setData+''].splice(idx, 1);
            }
            // システムリーチ修正
            if(jsons[projectNum][''+setData+''].length <= 0) {
              $(this).addClass(hide);
            } else {
              $(this).removeClass(hide);
            }
            return false;
          },
          close: function(event, ui) {
            // Value値
            const button = $(this).prev('[data-js-elem="clickList"]').find('button[type="button"]');
            let valueArr = [];
            button.each(function() {
              const value = $(this).attr('data-js-value');
              valueArr.push(value);
            });
            $(this).nextAll('[data-js-autocomplete="hidden"]').attr('value', valueArr);
            valueArr = [];
            return false;
          }
        }).click(function() {
          $(this).autocomplete('search', '');
        });
      });
      // ボタン削除
      // システムリーチ修正
      $(document).on(click, '[data-js-trigger="clickListClose"]', function (index, element) {
        // システムリーチ追加
        const projectElement = $(this).parents('[data-js-elem="project"]')
        const projectNum = projectElement.attr('data-project-num') || `${index}`;
        if (!jsons[projectNum]) return;
        const setValue = $(this).parents('button').attr('data-js-value'),
              setData = $(this).attr('data-js-source');
        // 配列に追加
        // システムリーチ修正
        if (jsons[projectNum][setData] && !jsons[projectNum][setData].includes(setValue)) {
          jsons[projectNum][setData].push(setValue); 
        }
        // Value値 配列から削除
        const thisValue = $(this).parents('button[type="button"]').attr('data-js-value'),
              hiddenValue = $(this).parents('[data-js-elem="clickList"]').nextAll('[data-js-autocomplete="hidden"]').attr('value');
        let str;
        if (hiddenValue !== undefined) {
          str = hiddenValue.replace(thisValue, '').replace(',,', ',').replace(/^,/, '').replace(/,$/, '');
        }
        // 処理
        $(this).parents('[data-js-elem="clickList"]').next('[data-js-elem="autocomplete"]').removeClass(hide);
        $(this).parents('[data-js-elem="clickList"]').nextAll('[data-js-autocomplete="hidden"]').attr('value', str);
        $(this).parents('[data-js-elem="clickListItem"]').remove();
      });
  //   },
  //   function () {
  //     //console.log('error');
  //   }
  // );
}

// 共通制御
function topCommon() {
  $(function () {
    // スムーススクロール
    !function(){
      const trigger = '[data-js-trigger="anchor"]';
      // システムリーチ追加
      if ($(document).find(trigger).length == 0) {
        return
      }
      $.easing.quart = function(x, t, b, c, d) {
        return -c * ((t = t / d - 1) * t * t * t - 1) + b;
      };
      $(document).on(click, trigger, function() {
        let href= $(this).attr('href'),
            target = $(href == "#" || href == "" ? 'html' : href);

        pos = target.offset().top;
        $('html, body').animate({ scrollTop: pos }, 400);
        return false;
      });
    }();
    // アコーディオン
    !function(){
      const trigger = '[data-js-trigger="acBtn"]',
            elem = '[data-js-elem="acConts"]';
      // 初期表示
      $(trigger+'.'+active).next(elem).show();
      // 制御
      $(document).on(click, trigger+':not(.'+active+')', function() {
        $(this).addClass(active).next(elem).slideDown();
      });
      $(document).on(click, trigger+'.'+active, function() {
        $(this).removeClass(active).next(elem).slideUp();
      });
    }();
    // タブ
    !function(){
      const tabs = '[data-js-elem="tabConts"]',
            tabNav = '[data-js-trig="tabNav"]';
      $(document).on(click, tabNav, function() {
        var container = $(this).parents('[data-js-elem="tabCtr"]');
        container.find(tabNav+'.'+active).removeClass(active);
        $(this).addClass(active);
        container.find(tabs+'.'+active).removeClass(active);
        var index = $(this).index();
        container.find(tabs).eq(index).addClass(active);
      });
    }();
    // BGM
    !function(){
      const triggerOn = '[data-js-trigger="audioOn"]',
            triggerOff = '[data-js-trigger="audioOff"]',
            $audio = $('[data-js-media="bgm"]').get(0);
      // システムリーチ追加
      if ($(document).find(triggerOn).length == 0 || $(document).find(triggerOff).length == 0) {
        return
      }
      $audio.volume = 0.3;
      $(document).on(click, triggerOn, function() {
        $audio.play();
        $(triggerOff).addClass(active);
        $(triggerOn).removeClass(active);
      });
      $(document).on(click, triggerOff, function() {
        $audio.pause();
        $(triggerOn).addClass(active);
        $(triggerOff).removeClass(active);
      });
    }();
  });
}

// スライダー
function topSlider() {
  $(function () {
    const elem = '[data-js-elem="projectSlider"]',
          slide = '[data-js-elem="projectSlider"] .swiper-slide';
    let followFingerVal;
    if($(body).hasClass('ua-sp')) {
      followFingerVal = false;
    } else {
      followFingerVal = true;
    }
    new Swiper( elem , {
      slidesPerView: 'auto',
      centeredSlides : true,
      loop: true,
      loopedSlides: slide.length,
      loopAdditionalSlides: 2,
      speed: 1000,
      followFinger: followFingerVal,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      on: {
        init: function () {
          $('.project-name').matchHeight();
        }
      }
    });
  });
}

// システムリーチ 修正
// (function ($) {
$(document).on('turbolinks:load', function() {
  /*==========================================================================
  /  グローバル変数
  /==========================================================================*/
  window.body = 'body';
  window.click = 'click';
  window.active = 'is-active';
  window.disable = 'is-disable';
  window.hide = 'is-hide';
  /*==========================================================================
  /  処理
  /==========================================================================*/
  configuration();
  if($('.wrapper').hasClass('page-top')){
    topCommon();
    topSlider();
  } else {
    common();
    form();
    mfp();
    autoComplete();
  }
  
  /*
  *
  * 以下、システムリーチ側で記載　↓
  *
  */
  
  // Google ReCaptcha の設定
  if ($('.recaptchaResponse')) {
    load(process.env.GOOGLE_RECAPTCHA_SITE_KEY).then((recaptcha) => {
      recaptcha.execute('contact').then((token) => {
        $(".recaptchaResponse").val(token);
      })
    })
  }

  // mfpをグローバルに定義
  window.e_agent_mfp = mfp
  
  // スキルシート編集画面
  // プロジェクト追加ボタンを押されたとき
  $('form').on('click', '.add_field', function (event) {
    // 現在時刻をミリ秒形式で取得
    let time = new Date().getTime()
    // ヘルパーで作ったインデックス値を↑と置換
    let regexp = new RegExp($(this).data('id'), 'g')
    // ヘルパーから渡した fields(HTML) を挿入
    // $(this).parent().parent().find(".sec-inner:last").append($(this).data('fields').replace(regexp, time))
    $(this).parent().parent().find(".border-ctr:last").after($(this).data('fields').replace(regexp, time))
    mfp();
    // システムリーチ追加
    $("div[data-js-elem='project']:visible").each(function(index) {
      $(this).find(".skill_sheet_items_order_num").val(index + 1)
      $(this).find(".project-ttl").text(`プロジェクト${index + 1}`)
    })
    autoComplete()
    event.preventDefault()
  })

  // 削除ボタンを押されたとき
  $('form').on('click', '.remove_field', function (event) {
    // 削除ボタンを押したフィールドの _destroy = true にする
    $(this).parent().prev('input[name*=_destroy]').val('true')
    // 削除ボタンが押されたフィールドを隠す
    // $(this).closest('div').hide()
    // システムリーチ修正
    $(this).closest("div[data-js-elem='project']").hide()
    // システムリーチ追加
    $(this).closest("div[data-js-elem='project']").find("input[required],select[required]").removeAttr("required")
    $("div[data-js-elem='project']:visible").each(function(index) {
      $(this).find(".skill_sheet_items_order_num").val(index + 1)
      $(this).find(".project-ttl").text(`プロジェクト${index + 1}`)
    })
    event.preventDefault()
  })
  
  // 案件作成画面（Tbrave権限）
  $('form select[name="matching_service[matching_company_id]"]').on('change', function (e) {
    if (!$("select[name='matching_service[user_id]']")) {
      return;
    }
    const target_select = $("select[name='matching_service[user_id]']").eq(0)
    target_select.children().remove()
    target_select.append($(`<option value="">-----</option>`))
    
    $.ajax({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      },
      url: `/matching/client_accounts/get_by_company?matching_company_id=${e.target.value}`,
      type: "GET"
    }).then(function (response) {
      response.users.forEach(function(user) {
        target_select.append($(`<option value="${user.id}">${user.last_name} ${user.first_name}</option>`))
        // target_select.append($("<option>").val(user.id).text(`${user.last_name} ${user.first_name}`))
      })
    })
  })
  
  const path_name = window.location.pathname
  
  // 案件検索画面
  // 検索条件をポップアップに反映
  if (path_name == "/matching/services/search") {
    // 各モーダルのid要素をキーにし、選択値の配列を値にした連想配列
    const selected_values = {}
    selected_values["occupation"] = $(".text-ctr-occupation p").text().split("／");
    selected_values["environment"] = $(".text-ctr-environment p").text().split("／");
    selected_values["role"] = $(".text-ctr-role p").text().split("／");
    selected_values["pref"] = $(".text-ctr-pref p").text().split("／");
    // 各モーダル内の選択されている値にチェックをつける
    Object.keys(selected_values).forEach(modalId => {
      selected_values[modalId].forEach(selected_value => {
        $(`#${modalId}`).find(`input[value="${selected_value}"]`).prop('checked', true)
      })
    })
  }

  // 案件編集画面、案件複製画面の初期値反映
  // 環境の選択値をポップアップに反映
  if (/\/matching\/services\/new|\/matching\/services\/[0-9]*\/edit/.test(path_name)) {
    $(".environment-ctr > .form-item-inner:visible").each((index, item) => {
      let selected_value = $(item).children().eq(0).find(".form-label").text()
      $(`input[value="${selected_value}"]`).prop('checked', true)
    })
  }
  
  // 住所検索
  $("#address-search-button").on("click", e => {
    $.ajax({
      url: `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${$("#postal-code-value").val()}`,
      type: "GET",
      dataType: 'json'
    }).then(function (response) {
      if (response.status == 200 && response.results && response.results.length > 0) {
        const response_result = response.results[0]
        $("#address-value").val(`${response_result.address1}${response_result.address2}${response_result.address3}`)
      }
    })
  })

  // 案件作成/編集画面で稼働方法のフルリモートのチェックの付け外し時の挙動を定義
  if ($("input[name='matching_service[work_styles][]']").length > 0) {
    $("input[name='matching_service[work_styles][]']").on("change", e => {
      const fullRemoteCheckbox = $("input[name='matching_service[work_styles][]'][value='フルリモート']");
      const onsiteCheckbox = $("input[name='matching_service[work_styles][]'][value='オンサイト']");
      const remoteCheckbox = $("input[name='matching_service[work_styles][]'][value='リモート']");
      const fullRemoteWorkDaysSelectBox = $("select[name='matching_service[full_remote_work_days]']");
      const onSiteWorkDaysSelectBox = $("select[name='matching_service[on_site_work_days]']");
      const remoteWorkDaysSelectBox = $("select[name='matching_service[remote_work_days]']");

      if (fullRemoteCheckbox.is(":checked")) {
        // 「フルリモート」がチェックされている場合
        // 「オンサイト」と「リモート」を無効化してチェックを外す
        onsiteCheckbox.prop("checked", false).prop("disabled", true);
        remoteCheckbox.prop("checked", false).prop("disabled", true);
        // 「フルリモート」の稼働日数を選択可能にする
        fullRemoteWorkDaysSelectBox.prop("disabled", false);
        // 「オンサイト」の稼働日数の選択値を初期化し、選択不可にする
        onSiteWorkDaysSelectBox.val("");
        onSiteWorkDaysSelectBox.prop("disabled", true);
        // 「リモート」の稼働日数の選択値を初期化し、選択不可にする
        remoteWorkDaysSelectBox.val("");
        remoteWorkDaysSelectBox.prop("disabled", true);
      } else {
        // 「フルリモート」のチェックが外れた場合は、「オンサイト」と「リモート」を有効化
        onsiteCheckbox.prop("disabled", false);
        remoteCheckbox.prop("disabled", false);
        // 「フルリモート」の稼働日数の選択値を初期化し、選択不可にする
        fullRemoteWorkDaysSelectBox.val("");
        fullRemoteWorkDaysSelectBox.prop("disabled", true);
      }
      if (onsiteCheckbox.is(":checked")) {
        // 「オンサイト」がチェックされている場合
        // 「オンサイト」の稼働日数を選択可能にする
        onSiteWorkDaysSelectBox.prop("disabled", false);
      } else {
        // 「オンサイト」がチェックされていない場合
        // 「オンサイト」の稼働日数の選択値を初期化し、選択不可にする
        onSiteWorkDaysSelectBox.val("");
        onSiteWorkDaysSelectBox.prop("disabled", true);
      }
      if (remoteCheckbox.is(":checked")) {
        // 「リモート」がチェックされている場合
        // 「リモート」の稼働日数を選択可能にする
        remoteWorkDaysSelectBox.prop("disabled", false);
      } else {
        // 「リモート」がチェックされていない場合
        // 「リモート」の稼働日数の選択値を初期化し、選択不可にする
        remoteWorkDaysSelectBox.val("");
        remoteWorkDaysSelectBox.prop("disabled", true);
      }
    })
    // 初期表示時にチェックされている場合の挙動を発火
    $("input[name='matching_service[work_styles][]']").trigger("change");
  }
  
  // 検索条件ボタン押下時
  $("#filter_search_submit_button").on("click", e => {
    $("#filter_search_form").submit()
  })
  
  /**
   * 拡張子が正しいか判定する.
   * @param  {string} ファイル名.
   * @return {Boolean} true:正しい.
   */
  const limitExtensions = "pdf|doc|docx|xls|xlsx"
  function isCorrectExtension(name) {
    // スペース以外の文字で始まってlimitExtensionsで定義した拡張子で終わる文字(大文字・小文字を区別しない[i])
    var format = new RegExp(`([^\s]+(\\.(${limitExtensions}))$)`, 'i');
    return format.test(name);
  }

  /**
   * ファイルサイズが正しいかを判定する.
   * @param  {number} ファイルサイズ(バイト単位).
   * @return {Boolean} true:正しい.
   */
  const limitMB = 10
  function isCorrectSize(size) {
    /** @type {number} 許容する最大サイズ(1MB). */
    var maxSize = 1024 * 1024 * limitMB;
    return size <= maxSize;
  }
  
  const limit_content_type = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]

  // 選択されたファイルのチェック
  $('input[type="file"][name="user[skill_sheet]"], input[type="file"][name="user[portfolio]"], input[type="file"][name="skill_sheet"], input[type="file"][name="portfolio"]').on("change", function(e){
    /** @type {object} 選択されたファイル. */    
    let file = e.target.files[0];
    if (file) {
      let errorMessage = ""
      // ファイル拡張子バリデーション
      if (!isCorrectExtension(file.name)) {
        errorMessage += `バリデーションエラー\n・ファイル拡張子は、${limitExtensions.replace(/\|/g, "、")}のみ可能です。`
      }
      // コンテンツタイプバリデーション
      if (limit_content_type.indexOf(file.type) == -1) {
        errorMessage += `${errorMessage ? "\n" : "バリデーションエラー\n"}・ファイルのコンテンツタイプは、${limit_content_type.join("、")}のみ可能です。`
      }
      // ファイルサイズバリデーション
      if (!isCorrectSize(file.size)) {
        errorMessage += `${errorMessage ? "\n" : "バリデーションエラー\n"}・ファイルサイズは、${limitMB}MBまでです。`
      }
      if (errorMessage) {
        $(e.target).val('');
        alert(errorMessage);
      }
    }
  })

  $('form[id="new_user"]').on("submit", function(e){
    let form = $(e.target)
    let password = form.find("input[name='user[password]']").val()
    let password_confirmation = form.find("input[name='user[password_confirmation]']").val()
    if (password && password_confirmation) {
      let errorMessage = ""
      if (password != password_confirmation) {
        errorMessage += "「パスワード」と「パスワード確認」は同じ値にしてください。"
      }

      // 大文字、小文字、数字、記号のうち３種類以上が必要で、10文字以上のバリデーション
      // @ see https://it-blue-collar-dairy.com/password_regex/
      let password_regexp = /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])|(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]|;:'",.<>?\\])|(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\[\]|;:'",.<>?\\])|(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\[\]|;:'",.<>?\\]))([a-zA-Z0-9!@#$%^&*()_+{}\[\]|;:'",.<>?\\]){10,}$/
      if (!password_regexp.test(password)) {
        errorMessage += `${errorMessage ? "\n" : ""}パスワードは「数字」「記号」「英小文字」「英大文字」から3種以上含め、10文字以上にしてください。`
      }

      if (errorMessage) {
        alert(errorMessage);
        return false;
      }
    }
  })
})
// })(jQuery);