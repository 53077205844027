// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// このファイルには全画面で使用するjsファイルのみ import もしくは require してください。
// @see https://cloudpack.media/51718

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
import 'bootstrap'
import '../stylesheets/application'
global.$ = require("jquery")
// require("jquery")
require("jquery-ui")
require("./jquery.uploadThumbs")
global.$ = require("jquery")
require("./library")
require("magnific-popup")
require("./environment.json")
require("./base")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
