export default function getUrlQueries() {
  // スペースは + になるため、予め + をスペースに戻す
  var queryStr = decodeURIComponent(window.location.search.slice(1).replace(/\+/g," "));  // 文頭?を除外
  var queries = {};

  // クエリがない場合は空のオブジェクトを返す
  if (!queryStr) {
    return queries;
  }

  // クエリ文字列を & で分割して処理
  queryStr.split('&').forEach(function(queryStr) {
    // = で分割してkey,valueをオブジェクトに格納
    var queryArr = queryStr.split('=');
    if (/\[\]$/.test(queryArr[0])) {
      // チェックボックスの場合
      if (queries[queryArr[0]]) {
        queries[queryArr[0]].push(queryArr[1])
      } else {
        queries[queryArr[0]] = [queryArr[1]]
      }
    } else {
      queries[queryArr[0]] = queryArr[1];
    }
  });

  return queries;
}
